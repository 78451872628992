import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import Loading from '../../elements/loading';
import Errors from '../../elements/errors';

import { UserContext, CustomerContext } from '../../context';
import AWSExports, { Region, SetCustomerID } from '../../aws-exports';
import Env from '../../env';
import { CheckForIndexHTMLChange, LightenDarkenColor } from '../../utilities';

const Login = React.lazy(() => import('../login'));
const Signup = React.lazy(() => import('../signup'));
const Confirm = React.lazy(() => import('../confirm'));
const Resend = React.lazy(() => import('../resend'));
const Forgot = React.lazy(() => import('../forgot'));
const Reset = React.lazy(() => import('../reset'));
const Home = React.lazy(() => import('../home'));

const IndexHTMLURL = 'https://s3-' + Region + '.amazonaws.com/' + (Env === 'prd' ? '' : `${Env}.`) + 'my.thor.tools/index.html';

const url = window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.') ? 'my.thor.tools' : window.location.hostname;
const isThorDomain = Boolean(url === 'my.thor.tools' || url === 'dev.my.thor.tools');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'loading',
      userContext: {
        authenticated: false,
        id: '',
        email: '',
        name: '',
        locale: '',
        changeLanguage: this.changeLanguage,
        changeName: this.changeName,
      },
      customerContext: {
        isThor: true,
        id: 'Thor',
        name: 'Thor',
        font: 'sans',
        colors: { primary: '#319795', secondary: '#805ad5' },
        currency: 'eur',
      },
    };
    this.secureSetState = this.secureSetState.bind(this);
    this.setCookiePopUp = this.setCookiePopUp.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  async getClientByCDN() {
    try {
      let { default: API } = await import('@aws-amplify/api');
      let data = await API.post('GetClientByCDN', '', { body: { url } });
      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  async changeName(n) {
    let { userContext } = this.state;
    userContext.name = n;
    this.secureSetState({ userContext });
    try {
      let { default: Auth } = await import('@aws-amplify/auth');
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { name: n });
    } catch (err) {
      console.log(err);
    }
  }

  async changeLanguage(l) {
    this.props.setActiveLanguage(l);
    let { userContext } = this.state;
    userContext.locale = l;
    this.secureSetState({ userContext });
    try {
      let { default: Auth } = await import('@aws-amplify/auth');
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { locale: l });
    } catch (err) {
      console.log(err);
    }
  }

  setCookiePopUp() {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: '#edf2f7',
          text: '#4a5568',
        },
        button: {
          background: '#319795',
          text: '#ffffff',
        },
      },
      secure: true,
      theme: 'classic',
      position: 'bottom-right',
      content: {
        message: this.props.translate('cookies.message'),
        dismiss: this.props.translate('cookies.dismiss'),
        link: this.props.translate('cookies.link'),
        href: 'https://thor.tools/privacy-policy-e-condizioni-contrattuali/',
      },
    });
  }

  changeStyle(customerContext) {
    document.documentElement.style.setProperty('--color-primary', customerContext.colors.primary);
    document.documentElement.style.setProperty('--color-primary-dark', LightenDarkenColor(customerContext.colors.primary, -10));
    document.documentElement.style.setProperty('--color-primary-darker', LightenDarkenColor(customerContext.colors.primary, -20));
    document.documentElement.style.setProperty('--color-primary-darkest', LightenDarkenColor(customerContext.colors.primary, -30));
    document.documentElement.style.setProperty('--color-primary-light', LightenDarkenColor(customerContext.colors.primary, 10));
    document.documentElement.style.setProperty('--color-primary-lighter', LightenDarkenColor(customerContext.colors.primary, 20));
    document.documentElement.style.setProperty('--color-primary-lightest', LightenDarkenColor(customerContext.colors.primary, 30));
    document.documentElement.style.setProperty('--color-secondary', customerContext.colors.secondary);
    document.documentElement.style.setProperty('--color-secondary-dark', LightenDarkenColor(customerContext.colors.secondary, -10));
    document.documentElement.style.setProperty('--color-secondary-darker', LightenDarkenColor(customerContext.colors.secondary, -20));
    document.documentElement.style.setProperty('--color-secondary-darkest', LightenDarkenColor(customerContext.colors.secondary, -30));
    document.documentElement.style.setProperty('--color-secondary-light', LightenDarkenColor(customerContext.colors.secondary, 10));
    document.documentElement.style.setProperty('--color-secondary-lighter', LightenDarkenColor(customerContext.colors.secondary, 20));
    document.documentElement.style.setProperty('--color-secondary-lightest', LightenDarkenColor(customerContext.colors.secondary, 30));
    switch (customerContext.font) {
      case 'sans':
        document.documentElement.style.setProperty(
          '--font-primary',
          `'Roboto',
					'ui-sans-serif',
					'system-ui',
					'-apple-system',
					'BlinkMacSystemFont',
					'"Segoe UI"',
					'"Helvetica Neue"',
					'Arial',
					'"Noto Sans"',
					'sans-serif',
					'"Apple Color Emoji"',
					'"Segoe UI Emoji"',
					'"Segoe UI Symbol"',
					'"Noto Color Emoji"'`
        );
        break;
      case 'serif':
        document.documentElement.style.setProperty(
          '--font-primary',
          `'Playfair Display',
					'ui-serif',
					'Georgia',
					'Cambria',
					'"Times New Roman"',
					'Times',
					'serif'`
        );
        break;
      case 'mono':
      default:
        document.documentElement.style.setProperty(
          '--font-primary',
          `'"Space Mono"',
					'ui-monospace',
					'SFMono-Regular',
					'Menlo',
					'Monaco',
					'Consolas',
					'"Liberation Mono"',
					'"Courier New"',
					'monospace'`
        );
        break;
    }
  }

  async componentDidMount() {
    this.isMounted = true;
    let reloadOnNextChange = false;
    let checkIndexInterval = setInterval(async () => {
      reloadOnNextChange = await CheckForIndexHTMLChange(IndexHTMLURL);
      if (reloadOnNextChange) {
        console.log('Found a new version of the webapp. Reloading on next page change.');
        clearInterval(checkIndexInterval);
      }
    }, 600000);
    this.props.history.listen((ev) => {
      if (reloadOnNextChange) {
        window.location.href = ev.pathname;
      }
    });

    let defaultLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    defaultLanguage = defaultLanguage.split('-')[0].toLowerCase();
    if (['it', 'en'].indexOf(defaultLanguage) === -1) {
      defaultLanguage = 'en';
    }
    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Italian', code: 'it' },
      ],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage,
      },
    });

    try {
      let { default: Auth } = await import('@aws-amplify/auth');
      let { default: API } = await import('@aws-amplify/api');
      let { default: PubSub } = await import('@aws-amplify/pubsub');
      let { default: Storage } = await import('@aws-amplify/storage');
      Auth.configure(AWSExports);
      API.configure(AWSExports);
      PubSub.configure(AWSExports);
      Storage.configure(AWSExports);

      let { default: translations } = await import('../../l10n');
      this.props.addTranslationForLanguage(translations.en, 'en');
      this.props.addTranslationForLanguage(translations.it, 'it');
      let { customerContext } = this.state;
      customerContext.isThor = true;

      if (!isThorDomain) {
        let customClient = await this.getClientByCDN();
        if (!customClient) {
          this.secureSetState({ status: 'cdnError' });
          return;
        } else {
          let customerContext = {
            isThor: isThorDomain,
            id: customClient.id,
            name: customClient.name,
            colors: customClient.colors,
            font: customClient.font,
            ids: customClient.ids,
          };
          this.changeStyle(customerContext);
          let metaThemeColor = document.querySelector('meta[name=theme-color]');
          metaThemeColor.setAttribute('content', customClient.colors.primary);
          SetCustomerID(customClient.id);
          this.secureSetState({ customerContext: customerContext });
        }
      }
      let data = await Auth.currentAuthenticatedUser();
      let userContext = {
        id: data.username,
        authenticated: true,
        email: data.attributes.email,
        name: data.attributes.name,
        locale: data.attributes.locale,
        changeLanguage: this.changeLanguage,
        changeName: this.changeName,
      };
      this.props.setActiveLanguage(userContext.locale);
      //this.setCookiePopUp();
      this.secureSetState({
        status: 'ready',
        userContext: userContext,
      });
    } catch (err) {
      this.props.setActiveLanguage(defaultLanguage);
      //this.setCookiePopUp();
      if (
        this.props.history.pathname !== '/login' &&
        this.props.history.pathname !== '/signup' &&
        this.props.history.pathname !== '/confirm' &&
        this.props.history.pathname !== '/resend' &&
        this.props.history.pathname !== '/forgot' &&
        this.props.history.pathname !== '/reset'
      ) {
        //this.props.history.replace('/');
      }
      setTimeout(() => this.secureSetState({ status: 'ready' }), 1000);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    if (this.state.status === 'loading') {
      return <Loading />;
    }
    if (this.state.status === 'cdnError') {
      return <Errors />;
    }
    return (
      <UserContext.Provider value={this.state.userContext}>
        <CustomerContext.Provider value={this.state.customerContext}>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                path='/login'
                exact
                render={() => (
                  <>
                    <Login userContext={this.state.userContext} customerContext={this.state.customerContext} />
                  </>
                )}
              />
              <Route path='/signup' exact render={() => <Signup userContext={this.state.userContext} customerContext={this.state.customerContext} />} />
              <Route path='/confirm' exact render={() => <Confirm userContext={this.state.userContext} customerContext={this.state.customerContext} />} />
              <Route path='/resend' exact render={() => <Resend userContext={this.state.userContext} customerContext={this.state.customerContext} />} />
              <Route path='/forgot' exact render={() => <Forgot userContext={this.state.userContext} customerContext={this.state.customerContext} />} />
              <Route path='/reset' exact render={() => <Reset userContext={this.state.userContext} customerContext={this.state.customerContext} />} />
              <Route
                path='*'
                exact
                render={() => (
                  <>
                    <Home userContext={this.state.userContext} customerContext={this.state.customerContext} />
                  </>
                )}
              />
            </Switch>
          </React.Suspense>
        </CustomerContext.Provider>
      </UserContext.Provider>
    );
  }
}

export default withRouter(withLocalize(App));
